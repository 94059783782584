import { FormGroup } from '@angular/forms';
import { FormSection } from '../../shared/models/form.config';
import { UiStore } from './ui.store';
import { action, makeObservable, observable, runInAction, toJS } from 'mobx';
import { TableQuickSortItem } from '../modals/table.modal';
import { AlertService } from '../services/alert.service';

export abstract class FormBaseStore<T> {
  abstract entityName: string;
  title: string = 'Add';
  addButtonLabel: string = 'Add ';
  submitButtonTitle: string = 'Save';
  submitButtonLoadingText: string = 'Saving';
  submitButtonAndNewTitle: string = 'Save & New';
  submitButtonAndNewLoadingTitle: string = 'Saving';
  cancelButtonText: string = 'Cancel';
  mainId: number | null = null; // Initialize with null
  isItemFetching: boolean = false;
  isSideFormOpen: boolean = false;
  formGroup = new FormGroup({});

  abstract fields: FormSection[];
  abstract quickSortItems: TableQuickSortItem[];
  abstract itemId: number | null;

  abstract convertFormToDTO(): any;
  abstract resetEntireState(): void;
  abstract convertResponseToForm(response: any): any;
  abstract updateFormOptions(): void;

  initialize(): void {}
  getRequiredMasters(): void {}
  loadItems(): void {}

  protected abstract alertService: AlertService;
  protected abstract uiStore: UiStore;
  protected abstract service: any;

  constructor() {
    makeObservable(this, {
      title: observable,
      addButtonLabel: observable,
      submitButtonTitle: observable,
      submitButtonLoadingText: observable,
      submitButtonAndNewTitle: observable,
      submitButtonAndNewLoadingTitle: observable,
      cancelButtonText: observable,
      isItemFetching: observable,
      isSideFormOpen: observable,
      formGroup: observable,
      openSideForm: action,
      closeSideForm: action,
      saveItem: action,
      fetchItem: action,
    });
  }

  openSideForm(item?: any): void {
    runInAction(() => {
      this.isSideFormOpen = true;
      if (item && 'id' in item) {
        this.title = `Update ${this.entityName}`;
        this.submitButtonTitle = 'Update';
        this.submitButtonLoadingText = 'Updating';
        this.submitButtonAndNewTitle = '';
        this.submitButtonAndNewLoadingTitle = '';
        this.fetchItem(item.id);
      } else {
        this.itemId = null;
        this.title = `Add ${this.entityName}`;
        this.submitButtonTitle = 'Save';
        this.submitButtonLoadingText = 'Saving';
        this.submitButtonAndNewTitle = 'Save & New';
        this.submitButtonAndNewLoadingTitle = 'Saving';
        this.updateFormOptions();
        this.resetForm();
      }
    });
  }

  closeSideForm(): void {
    this.itemId = null;
    this.isSideFormOpen = false;
    this.resetForm();
  }

  fetchItem(id: number): void {
    this.isItemFetching = true;
    this.itemId = id;
    let getReq;
    if (this.mainId) {
      getReq = this.service.get(this.mainId, this.itemId);
    } else {
      getReq = this.service.get(this.itemId);
    }
    getReq.subscribe(
      (response: any) => {
        runInAction(() => {
          this.updateFormOptions();
          this.resetForm();
          this.formGroup.patchValue(this.convertResponseToForm(response));
          this.isItemFetching = false;
        });
      },
      (error: any) => {
        runInAction(() => {
          console.error('Error fetching details', error);
          this.alertService.error('Error!', 'Failed to fetch item details.');
          this.isItemFetching = false;
        });
      }
    );
  }

  saveItem(isClose: boolean = false): void {
    this.uiStore.startSaving();

    if (!this.formGroup.valid) {
      this.uiStore.finishSaving();
      return;
    }

    const formData = this.convertFormToDTO();

    const serviceCall = this.mainId
      ? this.itemId
        ? this.service.update(this.mainId, this.itemId, formData)
        : this.service.create(this.mainId, formData)
      : this.itemId
      ? this.service.update(this.itemId, formData)
      : this.service.create(formData);

    this.serviceCall(serviceCall, isClose);
  }

  serviceCall(serviceCall: any, isClose: boolean): void {
    serviceCall.subscribe({
      next: (response: any) => {
        runInAction(() => {
          if (this.itemId) {
            this.alertService.success(
              `The ${this.entityName} details have been successfully updated.`,
              'Update Successful'
            );
          } else {
            this.alertService.success(
              `A new ${this.entityName} has been successfully created.`,
              'Creation Successful'
            );
          }
          this.uiStore.finishSaving();
          this.loadItems();
          this.resetForm();
          if (isClose) {
            this.closeSideForm();
          }
        });
      },
      error: (error: any) => {
        runInAction(() => {
          this.uiStore.finishSaving();
          console.error(
            `Failed to ${this.itemId ? 'update' : 'save'} data:`,
            error
          );
          this.alertService.error(
            'Error!',
            `Failed to ${this.itemId ? 'update' : 'save'} data.`
          );
        });
      },
    });
  }

  resetForm(): void {
    this.uiStore.formErrors = [];
    this.formGroup.reset();
  }
}
